import React, { useContext } from "react";

import type { DataMutate, Permission } from "../../../types/types";
import type {
  AttributeGroupSummary,
  CollectionSchema,
  PIMProduct,
  TemplateTabData,
} from "../../../types/types.PIM";
import { AccessHomePage } from "./Access/AccessHomePage";
import { Activity } from "./Activity/Activity";
import { ProductAssets } from "./Assets/ProductAssetsTabs";
import { CollectionComponent } from "./Collections/CollectionComponent";
import { ProductConnections } from "./Connections/Connections";
import type { IProductVisibilityData } from "./Connections/Connections";
import { ProductGroupCard } from "./ProductOverview/ProductGroupCard";
import { Tab } from "../../../components/ReachTabs/Tab";
import { TabList } from "../../../components/ReachTabs/TabList";
import { TabPanels } from "@reach/tabs";
import { CustomTabPanel } from "../../../components/ReachTabs/TabPanel";
import { ProductTabPanel } from "./ProductTabPanel";
import { Auth } from "../../../components/Auth";

export const ProductTabs = ({
  product,
  hasPermission,
  t,
  replaceProductId,
  mutate,
  isPortfolio = false,
  roleIsGuest = false,
  roleIsBuyer = false,
  refreshKey,
  onVisibilityDataFetched,
}: {
  product: PIMProduct;
  hasPermission: (p?: Permission | Permission[] | undefined) => boolean;
  t: (s: string) => string;
  replaceProductId: (id: string) => void;
  mutate: DataMutate<PIMProduct>;
  isPortfolio?: boolean;
  roleIsGuest?: boolean;
  roleIsBuyer?: boolean;
  refreshKey: any;
  onVisibilityDataFetched?: (visibilityData: IProductVisibilityData) => void;
}) => {
  const { roleIsSomeKindOfSeller } = useContext(Auth);
  const isSKUs = (collection: CollectionSchema) =>
    collection.name.toLowerCase().includes("skus");

  const skusCollection = product.product_schema.collections.find(isSKUs);
  const SEOGroup = product.product_schema.groups.find(
    (group) => group.name === "SEO"
  );

  const tabHasValues = (name: string) => {
    const tab = product.product_schema.layout.find((tab) => tab.name === name);
    let showTab = false;
    tab?.items.forEach((item) => {
      if (item.group_type === "collection") {
        const collection = product.product_schema.collections.find(
          (collection) =>
            collection.name.toLowerCase() === item.name.toLowerCase()
        );
        if (
          (!!collection?.value_rows?.length &&
            collection?.value_rows?.length > 0 &&
            ((!collection.is_restricted && roleIsGuest) || !roleIsGuest)) ||
          !isPortfolio
        ) {
          showTab = true;
        }
      } else {
        const group = product.product_schema.groups.find(
          (group) => group.name.toLowerCase() === item.name.toLowerCase()
        );

        const showGroup =
          (!!group?.attributes?.length &&
            group.name.toLowerCase() !== "seo" &&
            groupHasValues(group) &&
            ((!group.is_restricted && roleIsGuest) || !roleIsGuest)) ||
          !isPortfolio;

        if (showGroup) {
          showTab = true;
        }
      }
    });

    return showTab;
  };

  const groupHasValues = (group: AttributeGroupSummary) => {
    let hasValues = false;
    group?.attributes?.forEach((attribute) => {
      if (
        attribute.values?.length > 0 &&
        (!isPortfolio ||
          (isPortfolio && roleIsGuest && !attribute.is_restricted) ||
          (isPortfolio && !roleIsGuest))
      ) {
        hasValues = true;
      }
    });
    return hasValues;
  };

  const NewTabsList: JSX.Element[] = [];
  const NewTabPanelList: JSX.Element[] = [];

  product.product_schema.layout?.forEach((tab: TemplateTabData) => {
    if (tab.is_enabled) {
      switch (tab.tab_type) {
        case "documents":
          if (
            hasPermission("view_assets") ||
            roleIsBuyer ||
            roleIsGuest ||
            roleIsSomeKindOfSeller
          ) {
            // sellers should see an empty table so that they can add to it. Otherwise
            // we are showing nothing for the public/buyer view.
            const roleIsNonSeller = roleIsGuest || roleIsBuyer;
            const hasAssetsOrExternalLinks =
              product.number_of_assets > 0 ||
              product.number_of_generated_assets > 0 ||
              product.asset_external_links.length > 0;
            // an external link is a type of asset, from the perspective of the UI.
            // Its a separate object for unknown reasons

            if (
              (roleIsNonSeller && hasAssetsOrExternalLinks) ||
              (hasPermission("view_assets") &&
                !hasAssetsOrExternalLinks &&
                !isPortfolio) ||
              (hasPermission("view_assets") &&
                hasAssetsOrExternalLinks &&
                isPortfolio) ||
              (roleIsSomeKindOfSeller && !isPortfolio) ||
              (hasAssetsOrExternalLinks &&
                isPortfolio &&
                roleIsSomeKindOfSeller)
            ) {
              NewTabsList.push(<Tab key={tab.name}>{tab.display_name}</Tab>);
              NewTabPanelList.push(
                <CustomTabPanel key={tab.name} refreshKey={refreshKey}>
                  <ProductAssets
                    product={product}
                    fetchProductData={mutate}
                    replaceProductId={replaceProductId}
                    viewMode={isPortfolio}
                  />
                </CustomTabPanel>
              );
            }
          }
          break;

        case "seo":
          if (SEOGroup && !isPortfolio) {
            NewTabsList.push(<Tab key={tab.name}>{tab.display_name}</Tab>);
            NewTabPanelList.push(
              <CustomTabPanel key={tab.name} refreshKey={refreshKey}>
                <ProductGroupCard
                  product={product}
                  group={SEOGroup}
                  key={tab.name}
                  mutateProduct={mutate}
                  isPortfolio={false}
                  replaceProductId={replaceProductId}
                />
              </CustomTabPanel>
            );
          }
          break;

        case "sku":
          if (!!skusCollection && !isPortfolio) {
            NewTabsList.push(<Tab key={tab.name}>{tab.display_name}</Tab>);
            NewTabPanelList.push(
              <CustomTabPanel key={tab.name} refreshKey={refreshKey}>
                <CollectionComponent
                  collection={skusCollection}
                  product={product}
                  fetchProductData={mutate}
                  replaceProductId={replaceProductId}
                  isPortfolio={isPortfolio}
                />
              </CustomTabPanel>
            );
          }
          break;

        default:
          if (tabHasValues(tab.name)) {
            NewTabsList.push(<Tab key={tab.name}>{tab.display_name}</Tab>);
            NewTabPanelList.push(
              <CustomTabPanel key={tab.name} refreshKey={refreshKey}>
                <ProductTabPanel
                  tab={tab}
                  product={product}
                  mutateProduct={mutate}
                  replaceProductId={replaceProductId}
                  isPortfolio={isPortfolio}
                />
              </CustomTabPanel>
            );
          }
          break;
      }
    }
  });

  if (!isPortfolio) {
    if (hasPermission("modify_products")) {
      NewTabsList.push(<Tab key="access">{t("Access")}</Tab>);
      NewTabPanelList.push(
        <CustomTabPanel key="access" refreshKey={refreshKey}>
          <AccessHomePage product={product} mutateProduct={mutate} />
        </CustomTabPanel>
      );
    }
    NewTabsList.push(<Tab key="connections">{t("Connections")}</Tab>);
    NewTabPanelList.push(
      <CustomTabPanel key="connections" refreshKey={refreshKey}>
        <ProductConnections
          product={product}
          onVisibilityDataFetched={onVisibilityDataFetched}
        />
      </CustomTabPanel>
    );

    NewTabsList.push(<Tab key="activity">{t("Activity")}</Tab>);
    NewTabPanelList.push(
      <CustomTabPanel key="activity" refreshKey={refreshKey}>
        <div style={{ marginTop: "16px" }}>
          <Activity
            target_id={
              product.display_status === "staged" ||
              product.display_status === "unpublished_staged"
                ? product.published_product_id
                : product.id
            }
            mode="product"
          />
        </div>
      </CustomTabPanel>
    );
  }

  return (
    <>
      <TabList>{NewTabsList}</TabList>
      <TabPanels>{NewTabPanelList}</TabPanels>
    </>
  );
};
