import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import type { Column, Hooks, Row } from "react-table";
import { useTable, useSortBy, useRowSelect } from "react-table";
import {
  CaretDownIcon,
  CaretUpIcon,
  LoadingIcon,
  ReorderIcon,
} from "../Icons/Icons";
import { H3 } from "../Typography/Typography";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { checkTextOverflow } from "../../util/util";
import { TableCheckBox } from "../TableCheckBox/TableCheckBox";
import { useDrag, useDrop } from "react-dnd";
import { TableReorderControls } from "./TableReorderControls";
import type { ITableProps, TableRefProps } from "./utils";

export const TableWrapper = styled.div<{
  lastChildleftAlign?: boolean;
  showLeftBorder?: boolean;
  enableRowReorder?: boolean;
  firstRowWidthPercentage?: number;
}>`
  padding: 0;
  overflow-x: auto;
  table {
    border-spacing: 0;
    border: 0;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.secondaryBorder};
    border-collapse: collapse;
    th,
    td {
      margin: 0;
      padding: 16px 10px;
      border: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      :last-child {
        border-right: 0;
        text-align: ${({ lastChildleftAlign }) =>
          lastChildleftAlign ? "left" : "right !important"};
      }
      .__react_component_tooltip {
        display: ${({ enableRowReorder }) =>
          enableRowReorder ? "none !important" : "inline-block"};
      }
    }

    th {
      color: ${({ theme }) => theme.primaryTextColor};
      font-size: ${({ theme }) => theme.fontSizes.xs};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      margin-right: 4px;
      border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
      background: ${({ theme }) => theme.secondaryBG};
      span:after {
        content: "  ";
      }
    }
    thead th:first-child {
      border-left: ${({ showLeftBorder, theme }) =>
        showLeftBorder ? `4px solid ${theme.secondaryBorder}` : "none"};
    }
    tbody td {
      background: ${({ theme }) => theme.primaryBG};
      border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
      font-size: ${({ theme }) => theme.fontSizes.small};
      color: ${({ theme }) => theme.primaryTextColor};
      .SingleDatePickerInput_calendarIcon {
        padding: 6px 3px !important;
        top: 2px !important;
        svg {
          width: 19px !important;
          height: 19px !important;
        }
      }
      .ql-editor {
        padding: 8px;
      }
      .ql-toolbar {
        padding: 3px 0px 2px 4px !important;
        margin: 0px 0px !important;
        min-width: 175px;
      }
      .ql-formats {
        margin-right: 0 !important;
        button {
          padding: 4px !important;
          width: 24px !important;
          height: 24px !important;
          margin-right: 5px !important;
          margin-bottom: 4px !important;
          svg {
            height: 12px !important;
            width: 12px !important;
          }
        }
      }
      div.textField {
        height: auto;
        padding: 0px;
        font-size: ${({ theme }) => theme.fontSizes.xs};
        min-width: 100px;
        input {
          padding: 7px 4px;
          font-size: ${({ theme }) => theme.fontSizes.small} !important;
          height: auto;
          line-height: 18px;
          &::placeholder {
            font-size: ${({ theme }) => theme.fontSizes.xs} !important;
          }
        }
      }

      div.select__control {
        height: auto;
        min-height: 25px;
        flex-wrap: nowrap;
        min-width: 100px;
        .select__value-container {
          margin-top: 0;
          padding: 0px 3px;
          position: relative;
          div[class*="SelectBoxShared__InterDiv"] {
            display: flex;
            margin-top: 0px !important;
            align-content: center;
            align-items: center;
            height: 32px;
          }
          .select__placeholder {
            top: 15px;
            font-size: ${({ theme }) => theme.fontSizes.xs} !important;
            position: absolute;
          }
        }
        .select__indicator {
          padding: 2px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      &:has(> a) {
        padding: 0;
        a {
          color: ${({ theme }) => theme.primaryTextColor};
          padding: 16px 10px;
          text-decoration: none;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    tbody tr {
      td:first-child {
        border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
        width: ${({ firstRowWidthPercentage }) =>
          firstRowWidthPercentage ? `${firstRowWidthPercentage}%` : "unset"};
      }
      &.unread td:first-child {
        border-left: 4px solid ${({ theme }) => theme.brandColor};
      }

      &:hover td {
        background: ${({ theme }) => theme.primaryButtonBG};
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const Loading = styled.div`
  width: 100%;
  padding: 100px 10px;
  text-align: center;
  background: ${({ theme }) => theme.primaryBG};
`;

export const NoData = styled.div<{ error?: boolean }>`
  width: 100%;
  padding: 100px 10px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme, error }) =>
    error ? theme.errorColor : theme.secondaryTextColor};
  background: ${({ theme }) => theme.primaryBG};
`;

const StyledH3 = styled(H3)`
  margin: 0 0 10px;
`;

export type SortBy = {
  desc: boolean;
  id: string;
};

type DragItem = {
  type: string;
  index: number;
  data: any;
};

/**
 * @param handleSort - is passed into Table to enable sorting, and you must explicitly use
 * @param disableSortBy -  for columns where sorting is not supported.
 * @param pageIndex -  is used with the
 * @param  handleSelectRows -  to ensure selected rows are persisted across pages
 */
export const Table = React.forwardRef<TableRefProps, ITableProps<any>>(
  <TableData extends object>(
    {
      columns,
      data,
      isLoading,
      title,
      handleSort,
      rowHover,
      error,
      rowClick,
      link,
      lastChildleftAlign,
      Placeholder,
      hiddenColumns,
      handleSelectRows,
      enableReorder = false,
      showReorderControls = false,
      handleTableReorder,
      handleRowReorder,
      reorderConfirmationMessage,
      showLeftBorder = true,
      firstRowWidthPercentage,
      defaultSelectedRows,
      pageIndex,
      name,
      handleMoveRow,
      handleDrop,
      rowSelectionType = "checkbox",
    }: ITableProps<TableData>,
    ref: React.ForwardedRef<TableRefProps>
  ) => {
    const [records, setRecords] = useState(data);
    const [table_body, set_table_body] =
      useState<HTMLTableSectionElement | undefined>();
    const [enableRowReorder, setEnableRowReorder] =
      useState<boolean>(enableReorder);
    const [selectedRowAndPages, setSelectedRowsAndPages] =
      useState<{ [pageNumber: string]: Row<TableData>[] }>();
    const [hasSelectedRowsChanged, setHasSelectedRowsChanged] = useState(false);
    const [is_default_rows_set, set_is_default_rows_set] = useState(false);
    const [is_all_items_selected, set_is_all_items_selected] = useState(false);
    const [dragRef] = useState(React.useRef<HTMLDivElement>(null));
    const dropRef = React.useRef<HTMLDivElement>(null);

    const hooksFunc = (hooks: Hooks) => {
      if (handleSelectRows) {
        hooks.visibleColumns.push((columns: Column[]) => [
          {
            id: "selection",
            Header: ({
              getToggleAllRowsSelectedProps,
              isAllRowsSelected,
              autoResetSelectedRows,
            }: any) => {
              const { onChange, ...rest } = getToggleAllRowsSelectedProps();
              const updatedOnChange = (
                e: React.MouseEvent<HTMLInputElement, MouseEvent>
              ) => {
                setHasSelectedRowsChanged(true);
                onChange(e);
              };
              return rowSelectionType === "checkbox" ? (
                <div>
                  <TableCheckBox
                    onChange={updatedOnChange}
                    {...rest}
                    disabled={isAllRowsSelected && !autoResetSelectedRows} // autoResetSelectedRows is false when all rows across pages are selected
                  />
                </div>
              ) : (
                <></>
              );
            },
            Cell: ({ row, isAllRowsSelected, autoResetSelectedRows }: any) => {
              const { onChange, ...rest } = row.getToggleRowSelectedProps();
              const updatedOnChange = (
                e: React.MouseEvent<HTMLInputElement, MouseEvent>
              ) => {
                setHasSelectedRowsChanged(true);
                onChange(e);
              };
              return (
                <div>
                  <TableCheckBox
                    input_type={rowSelectionType}
                    handleChange={updatedOnChange}
                    value={row.original.id}
                    name={name}
                    onChange={updatedOnChange}
                    {...rest}
                    disabled={isAllRowsSelected && !autoResetSelectedRows} // autoResetSelectedRows is false when all rows across pages are selected
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    };

    const use_table_props = useMemo(() => {
      const selectedRows = (() => {
        if (pageIndex && selectedRowAndPages) {
          return selectedRowAndPages[String(pageIndex)];
        }
        if (defaultSelectedRows === "all") {
          set_is_all_items_selected(true);
          return [];
        }
        return defaultSelectedRows?.["1"] ?? [];
      })();
      return {
        columns,
        data: records,
        manualSortBy: !!handleSort,
        defaultCanSort: false,
        disableSortRemove: true,
        autoResetSelectedRows: !is_all_items_selected,
        initialState: {
          hiddenColumns: hiddenColumns ? hiddenColumns : [],
          selectedRowIds: selectedRows?.reduce((acc, row) => {
            acc[row.id] = true;
            return acc;
          }, {} as Record<string, boolean>),
        },
      };
    }, [
      columns,
      defaultSelectedRows,
      handleSort,
      hiddenColumns,
      is_all_items_selected,
      pageIndex,
      records,
      selectedRowAndPages,
    ]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      state: { sortBy },
      selectedFlatRows,
      toggleAllRowsSelected,
    } = useTable(use_table_props, useSortBy, useRowSelect, (hooks: any) =>
      hooksFunc(hooks)
    );
    const { t } = useTranslation();
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    const cancelReorder = () => {
      setRecords(data);
      setEnableRowReorder(false);
    };

    const saveReorder = () => {
      if (handleTableReorder) {
        handleTableReorder(records);
      }
      setEnableRowReorder(false);
    };

    const getRowElement = (rowIdx: string) => {
      return Array.from(table_body?.children ?? []).find(
        (row: Element) =>
          (row as HTMLTableRowElement)?.dataset?.testid === rowIdx
      ) as HTMLTableRowElement;
    };

    const moveRow = <ItemType extends TableData>(
      old_index: number,
      new_index: number,
      item: ItemType
    ) => {
      const list = [...records];
      if (records.indexOf(item as TableData) > -1) {
        list.splice(new_index, 0, list.splice(old_index, 1)[0]); // move item to new index
        setRecords(list);
      } else {
        list.splice(new_index, 0, item); // insert item to new index
      }
      if (handleMoveRow) {
        handleMoveRow({
          item,
          source: name ?? "table",
          data: list,
        });
      }
    };

    const [, , preview] = useDrag({
      type: "row",
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const [, drop] = useDrop({
      accept: "row",
      drop(_item, _monitor) {
        if (handleDrop) {
          handleDrop();
        }
      },
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = (item as DragItem).index;
        const hoverIndex = 0;

        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset?.y
          ? clientOffset?.y - hoverBoundingRect.top
          : 0;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 20) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY + 20) {
          return;
        }
        // Time to actually perform the action
        let timeout = 0;
        timeout = setTimeout(() => {
          moveRow(dragIndex, hoverIndex, (item as DragItem).data);
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          (item as DragItem).index = hoverIndex;
          clearTimeout(timeout);
        });
      },
    });

    const onResetSelectedRows = () => {
      setSelectedRowsAndPages(undefined);
      set_is_all_items_selected(false);
      toggleAllRowsSelected(false);
      if (handleSelectRows) {
        handleSelectRows({});
      }
    };

    const onSelectAllItems = () => {
      setSelectedRowsAndPages(undefined);
      set_is_all_items_selected(true);
      let timeout_id = setTimeout(() => {
        toggleAllRowsSelected(true);
        clearTimeout(timeout_id);
      });
      if (handleSelectRows) {
        handleSelectRows("all");
      }
    };

    // TODO need to handle the distinction between an empty array because a search
    // result returned nothing and an empty array because there is nothing at all.
    const displayFallback = () => {
      if (!isLoading && !error && data.length === 0) {
        return Placeholder ? (
          <div style={{ padding: "0px" }} ref={dropRef}>
            {Placeholder}
          </div>
        ) : (
          <NoData>{t("No Results found.")}</NoData>
        );
      }
    };

    React.useImperativeHandle(ref, () => {
      return {
        resetSelectedRows() {
          onResetSelectedRows();
        },
        selectAllItems() {
          onSelectAllItems();
        },
        resetIsDefaultSelected() {
          set_is_default_rows_set(false);
        },
      };
    });

    useEffect(() => {
      if (enableRowReorder) {
        preview(drop(dropRef));
      }
    }, [enableRowReorder, preview, drop, dropRef, dragRef]);

    useEffect(() => {
      if (handleRowReorder && records) {
        handleRowReorder(records);
      }
    }, [records, handleRowReorder]);

    useEffect(() => {
      if (handleSort) {
        handleSort(sortBy);
      }
    }, [handleSort, sortBy]);

    useEffect(() => {
      if (hasSelectedRowsChanged) {
        let rows: { [pageNumber: string]: Row<TableData>[] } = {};
        if (pageIndex) {
          if (rowSelectionType === "checkbox") {
            setSelectedRowsAndPages((prev) => {
              const updated_rows = {
                ...prev,
                [String(pageIndex)]: selectedFlatRows,
              };
              rows = updated_rows;
              return updated_rows;
            });
          } else {
            setSelectedRowsAndPages((prev) => {
              const prev_row_ids = prev
                ? Object.values(prev)
                    .flat()
                    .map((row) => (row.original as { id: string }).id)
                : [];
              const filtered_row = selectedFlatRows.filter((row) =>
                prev_row_ids.every(
                  (id) => id !== (row.original as { id: string }).id
                )
              )[0];
              rows = { [String(pageIndex)]: [filtered_row] };
              return rows;
            });
          }
        } else {
          rows =
            rowSelectionType === "checkbox"
              ? { "1": selectedFlatRows }
              : { "1": [selectedFlatRows[0]] };
        }
        if (handleSelectRows) {
          let timeout_id = setTimeout(() => {
            handleSelectRows(rows);
            clearTimeout(timeout_id);
          });
        }
      }
      return () => {
        setHasSelectedRowsChanged(false);
      };
    }, [
      handleSelectRows,
      hasSelectedRowsChanged,
      pageIndex,
      rowSelectionType,
      selectedFlatRows,
    ]);

    useEffect(() => {
      if (defaultSelectedRows && !is_default_rows_set) {
        if (defaultSelectedRows === "all") {
          setSelectedRowsAndPages(undefined);
          set_is_all_items_selected(true);
          let timeout_id = setTimeout(() => {
            toggleAllRowsSelected(true);
            clearTimeout(timeout_id);
          });
        } else {
          setSelectedRowsAndPages(defaultSelectedRows);
        }
        set_is_default_rows_set(true);
      }
    }, [defaultSelectedRows, is_default_rows_set, toggleAllRowsSelected]);

    useEffect(() => {
      if (handleRowReorder) {
        setEnableRowReorder(enableReorder);
      }
    }, [enableReorder, handleRowReorder]);

    useEffect(() => {
      if (data) {
        setRecords(data);
      }
    }, [data]);

    useEffect(() => {
      if (tableBodyRef.current) {
        set_table_body(tableBodyRef.current);
      }
    }, []);

    return (
      <TableWrapper
        id="table_wrapper_id"
        lastChildleftAlign={lastChildleftAlign}
        showLeftBorder={showLeftBorder}
        enableRowReorder={enableRowReorder}
        firstRowWidthPercentage={firstRowWidthPercentage}
      >
        <FlexWrapper>
          <div>{title && <StyledH3>{title}</StyledH3>}</div>
          <div>
            {showReorderControls && records.length > 1 && (
              <TableReorderControls
                enableRowReorder={enableRowReorder}
                handleEnableRowReorder={() => setEnableRowReorder(true)}
                handleSaveReorder={saveReorder}
                handleCancelReorder={cancelReorder}
                reorderConfirmationMessage={reorderConfirmationMessage}
              />
            )}
          </div>
        </FlexWrapper>

        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, headerGroupIndex) => (
              <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
                {enableRowReorder && (
                  <th
                    key={0}
                    style={{ cursor: "pointer", maxWidth: "50px" }}
                  ></th>
                )}
                {headerGroup.headers.map((column: any, columnIndex: number) => (
                  <th
                    key={enableRowReorder ? columnIndex + 1 : columnIndex}
                    {...(handleSort
                      ? column.getHeaderProps(column.getSortByToggleProps())
                      : column.getHeaderProps())}
                    style={{
                      textAlign: column.align === "right" ? "right" : "left",
                    }}
                  >
                    <span
                      style={
                        handleSort && !column.disableSortBy
                          ? { cursor: "pointer" }
                          : undefined
                      }
                    >
                      {column.render("Header")}
                    </span>
                    <span>
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <CaretDownIcon height={12} width={12} />
                        ) : (
                          <CaretUpIcon height={12} width={12} />
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody ref={tableBodyRef} {...getTableBodyProps()}>
            {data &&
              rows.map((row: Row<TableData>, i: number) => {
                prepareRow(row);
                const rowElement = getRowElement(
                  String((data[i] as { id: string | number })?.id ?? i)
                );
                return (
                  <TableRowElement
                    data={records}
                    index={i}
                    row={row}
                    moveRow={moveRow}
                    {...row.getRowProps()}
                    rowHover={rowHover}
                    rowClick={enableRowReorder ? () => {} : rowClick}
                    link={link}
                    rowElement={rowElement}
                    enableRowReorder={enableRowReorder}
                    handleDrop={handleDrop}
                  />
                );
              })}
          </tbody>
        </table>
        {isLoading && (
          <Loading>
            <LoadingIcon />
          </Loading>
        )}
        {error && <NoData error>{t("Error loading the table")}</NoData>}
        {displayFallback()}
      </TableWrapper>
    );
  }
);

const TableRowElement = <TableData extends object>({
  data,
  row,
  index,
  moveRow,
  rowClick,
  link,
  rowHover,
  rowElement,
  enableRowReorder,
  handleDrop,
}: {
  data: TableData[];
  row: Row<TableData>;
  index: number;
  moveRow: (dragIndex: number, dropIndex: number, item: TableData) => void;
  handleDrop?: () => void;
  rowClick:
    | ((e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void)
    | undefined;
  link?: string;
  rowHover?: (row: any) => string | JSX.Element | null;
  rowElement: HTMLTableRowElement;
  enableRowReorder: boolean;
}) => {
  const [dragRef] = useState(React.useRef<HTMLTableCellElement>(null));
  const dropRef = React.useRef<HTMLTableRowElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: "row",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = (item as DragItem).index;
      const hoverIndex = index;

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset?.y
        ? clientOffset?.y - hoverBoundingRect.top
        : 0;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      let timeout = 0;
      timeout = setTimeout(() => {
        moveRow(dragIndex, hoverIndex, (item as DragItem).data);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        (item as DragItem).index = hoverIndex;
        clearTimeout(timeout);
      });
    },
    drop() {
      if (handleDrop) {
        handleDrop();
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "row",
    item: { type: "row", index, data: data[index], targetId: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getCellElement = ({
    rowElement: row,
    cellIdx,
  }: {
    rowElement: HTMLTableRowElement;
    cellIdx: number;
  }) => {
    if (row) {
      const cell = Array.from(row.children).find(
        (cell: Element) =>
          (cell as HTMLTableCellElement)?.dataset?.testid === String(cellIdx)
      ) as HTMLElement;
      return cell;
    }
    return null;
  };

  useEffect(() => {
    if (enableRowReorder) {
      preview(drop(dropRef));
      drag(dragRef);
    }
  }, [enableRowReorder, preview, drag, drop, dropRef, dragRef]);

  if (data) {
    return (
      <tr
        //key={data[index].id}
        {...row.getRowProps()}
        style={{
          opacity: isOver ? 0.3 : 1,
          cursor: rowClick && !enableRowReorder ? "pointer" : "default",
        }}
        id={String((data[index] as { id: string }).id)}
        data-testid={(data[index] as { id: string }).id}
        onClick={link ? undefined : rowClick}
        className={(row.original as any).unread ? "unread" : ""}
        ref={dropRef}
      >
        {enableRowReorder && (
          <td
            ref={dragRef}
            style={{
              cursor: isDragging ? "grabbing" : "grab",
              maxWidth: "50px",
            }}
          >
            <ReorderIcon />
          </td>
        )}
        {row.cells.map((cell: any, cellIndex: number) => {
          const cellElement = getCellElement({
            rowElement,
            cellIdx: cellIndex,
          });
          const isTextOverflow = cellElement
            ? checkTextOverflow(cellElement).widthOverflow
            : false;
          if (cell.isRowSpanned) return null;
          else {
            return (
              <td
                key={cellIndex}
                data-testid={cellIndex}
                data-tip={
                  rowHover
                    ? rowHover({
                        ...cell,
                        isTextOverflow,
                      })
                    : null
                }
                data-for={`row-tooltip-${cell.value}`}
                {...cell.getCellProps()}
                style={{
                  overflow: cell.column?.overflow ?? "hidden",
                  textAlign: cell.column.align === "right" ? "right" : "left",
                }}
                rowSpan={cell.rowSpan}
              >
                {(data[index] as { rowURL: string }).rowURL ? (
                  <a
                    href={`${String(
                      (data[index] as { rowURL: string }).rowURL
                    )}`}
                  >
                    {cell.render("Cell")}
                  </a>
                ) : (
                  cell.render("Cell")
                )}
                {isTextOverflow && (
                  <ReactTooltip
                    id={`row-tooltip-${cell.value}`}
                    place="top"
                    data-html={true}
                    effect="solid"
                    backgroundColor="#60676f"
                    multiline={true}
                  >
                    {rowHover
                      ? rowHover({
                          ...cell,
                          isTextOverflow,
                        })
                      : null}
                  </ReactTooltip>
                )}
              </td>
            );
          }
        })}
      </tr>
    );
  } else {
    return null;
  }
};
