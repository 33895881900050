import type {
  Currency,
  CurrencyCode,
  TagClassificationConfig,
  DownloadLinkOptions,
  FilterLabel,
  IAddress,
  IDeliveryTerm,
  InAppNotificationsResponse,
  IOrderItem,
  IPaymentMode,
  IPaymentTerm,
  OptionType,
  OrderStatus,
  OrderStatusAction,
  OrderStatusLabel,
  PaymentModePaginatedOutput,
  Product,
  ProductApplication,
  ProductApplicationBrokenId_DO_NOT_USE,
  ProductSKU,
  SingleStorefrontLocalization,
  StorefrontEdition,
  StorefrontMetaData,
  SupportedLanguage,
  Tenant,
  User,
  UUID,
  WithChildren,
  SampleRequestStatus,
  SampleStatusAction,
  SampleStatusLabel,
  LeadAssignee,
} from "../types/types";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Axios from "axios";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import type { FunctionComponent, ReactNode } from "react";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import React, { useContext } from "react";
import type {
  FieldValues,
  UseFormMethods,
  UseFormOptions,
} from "react-hook-form";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import type { ConfigInterface } from "swr";
import useSWR from "swr";
import { Auth, useAuthContext } from "../components/Auth";
import type { ChipType } from "../components/Chips/Chips";
import { endpoints } from "../endpoints";
import { Store } from "../Store";
import { getBrowserLanguage } from "../util/util-components";
import { countryCodeMap } from "./phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as yup from "yup";
import { strings } from "./strings";
import type { SortingRule } from "react-table";
import { ErrorHandler } from "../ErrorHandler";
import type { ArrayParam } from "use-query-params";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import axios from "axios";
import { match } from "ts-pattern";
import type {
  AttributeObjectType,
  AttributeValue,
  PIMProduct,
  PIMProductBase,
  ProductStatusType,
} from "../types/types.PIM";
import { Loader } from "../components/Loader/Loader";

type ConditionalWrapperProps = {
  condition: unknown | boolean;
  wrapper: (children: ReactNode) => JSX.Element;
  falseWrapper: (children: ReactNode) => JSX.Element;
  children: ReactNode;
};

/**
 * A utility function to conditionally wrap elements.
 *
 * If the condition prop returns true wrap the children in `wrapper`.
 *
 * if false wrap them in `falseWrapper`.
 *
 * @param condition unknown that evaluate to boolean
 * @param wrapper (children: ReactNode) => JSX.Element
 * @param falseWrapper (children: ReactNode) => JSX.Element
 * @param children ReactNode
 */
export const ConditionalWrapper = ({
  condition,
  wrapper,
  falseWrapper,
  children,
}: ConditionalWrapperProps) =>
  condition ? wrapper(children) : falseWrapper(children);

/**
 * ErrorWithStatus extends the default `Error` object
 * so we can access the status code outside of fetcher
 */
export class ErrorWithStatus extends Error {
  // This is sourced from
  // https://old.reddit.com/r/typescript/comments/9u9107/extends_error_not_working/e92juau/

  // You have to extend Error, set the __proto__ to Error, and use
  // Object.setPrototypeOf in order to have a proper custom error type in JS.
  // Because JS/TS are dumb sometimes, and all three are needed to make this
  // work in all browsers.

  __proto__ = Error;

  public status: number;
  public message: string;

  constructor(status: number, message: string) {
    super();
    this.status = status;
    this.message = message;

    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ErrorWithStatus.prototype);
  }
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

type NetworkErrorWithMessage = {
  response: { data: { message: string } };
};

export function isNetworkErrorWithMessage(
  error: unknown
): error is NetworkErrorWithMessage {
  const message = (error as any)?.response?.data?.message;
  return typeof message === "string" && message !== "";
}

/**
 * Get tenant slug from URL. For the most part we should be using the storefront
 * metadata endpoint to retrieve this, and for that reason this is not exported.
 * If for some reason there are future pages that don't follow the structure of
 * having the slug second this will need to be changed.
 * @returns string
 */
const getTenantSlug = (): string => {
  return window.location.pathname.split("/")[2];
};

/**
 * determine if we are on a private page based on URL. This means pages only accesible to logged in users.
 * Note that some public pages change content/layout if user is logged in, this does not handle that case.
 * @returns boolean
 */
export const isPrivatePage = (): boolean => {
  const slug = getTenantSlug();
  return (
    window.location.pathname.includes(`/store/${slug}/account`) ||
    window.location.pathname.includes(`/store/${slug}/admin`)
  );
};

/**
 * Handle errors on global level. this can display notifications for specific errors,
 * and make calls to error reporting service. Currently it handles 401
 * errors globally to redirect to a tenant specific login page.
 * @param error
 * @param key
 */
export const SwrOnError = (error: AxiosError, key: string) => {
  /*
   * FAQ:
   * Q: why don't we use react router here?
   * A: This is not inside of a route, swr is above it in the tree because
   *  the Auth provider needs swr.
   *
   * Q: Why don't we dispatch something to Auth/Store
   * A: because it would be an invalid hook call.
   *
   * Q: Does this mean we rely strongly on the tenant slug always being the
   *  second part of any URL?
   * A: Yes. I think all current URLS fit that pattern. If we for some
   *  reason have a need for URLS to break this convention behind login we
   *  can first verify whether the part of the URL that is expected to be a
   *  tenant slug is valid by hitting the metadata endpoint?
   *
   *
   * */
  if (isAxiosError(error)) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      // This does not match /store/xyz pages, which may be behind
      // login for
      // a buyer. In that case the user will fall through to the public
      // route silently. We do this because 401 is an expected response on
      // public pages for non logged in users.

      if (isPrivatePage()) {
        window.location.replace(`/store/${getTenantSlug()}/login`);
      }
    }
  }
};

/**
 * appends url to base url, errors are unhandled here. Axios.get will throw for
 * 4xx & 5xx responses and the error swr error object will be populated to be
 * handled declaratively.
 *
 * Axios throws an AxiosError for an error response (anything that's not 2xx,
 * e.g. 404), and that includes an AxiosResponse:
 * error.response.status
 * error.response.data
 * error.response.headers etc.If no response was received the AxiosError has a request property:  error.request
 * @see https://github.com/axios/axios#handling-errors
 *
 */
export async function fetcher<Data = unknown>(
  url: string,
  requestConfig?: AxiosRequestConfig
) {
  const { data } = await Axios.get<Data>(url, requestConfig);

  return data;
}

/**
 * These match up directly with the styled components theme
 * media queries.
 *
 * xl: `(min-width: 1025px)`,
 *
 * large: `(max-width: 1024px)`, //For iPad Pro...
 *
 * medium: `(max-width: 768px)`, //For regular iPad, Tablets...
 *
 * small: `(max-width: 425px)`, //For Mobile
 */
export function useMediaQueries() {
  const isXlScreen = useMediaQuery({
    query: "(min-width: 1025px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 425px)",
  });

  return {
    isXlScreen,
    isLargeScreen,
    isMediumScreen,
    isSmallScreen,
  };
}

interface IPageForEditionArgs {
  DefaultPage: FunctionComponent;
  StarterEditionPage?: FunctionComponent;
  BusinessEditionPage?: FunctionComponent;
  EnterpriseEditionPage?: FunctionComponent;
  PIMBasicEditionPage?: FunctionComponent;
}

/**
 * Returns a React function component that renders different page components
 * depending on the `edition` of the storefront. The page components to be
 * rendered are passed in as arguments. E.g. used to show a placeholder page
 * if a particular route is not supported by a given edition.
 *
 * @param args.DefaultPage - Page component to render by default.
 * @param args.StarterEditionPage - Component for `starter` edition.
 * @param args.BusinessEditionPage - Component for `business` edition.
 * @param args.EnterpriseEditionPage - Component for `enterprise` edition.
 * @param args.PIMBasicEditionPage - Component for `pim` edition.
 */
export const pageForEdition = ({
  DefaultPage,
  StarterEditionPage,
  BusinessEditionPage,
  EnterpriseEditionPage,
  PIMBasicEditionPage,
}: IPageForEditionArgs): FunctionComponent => {
  return ({ children }) => {
    const { edition } = useStoreState();

    const map: Map<StorefrontEdition, FunctionComponent | undefined> = new Map([
      ["starter", StarterEditionPage],
      ["business", BusinessEditionPage],
      ["enterprise", EnterpriseEditionPage],
      ["pim", PIMBasicEditionPage],
    ]);
    const PageComponent = map.get(edition) || DefaultPage;

    return <PageComponent>{children}</PageComponent>;
  };
};

export function getUserName(
  firstName: string | undefined,
  lastName: string | undefined
): string {
  if (!firstName || !lastName) return "";
  else return `${firstName} ${lastName}`;
}

/**
 * A hook to provide the `historyGoBack` helper function.
 */
export const useHistoryGoBack = () => {
  const history = useHistory();
  return {
    /**
     * Calls `history.goBack` unless there's nowhere in history to go back to. In
     * that case, call `history.push` with the provided fall back destination.
     *
     * @param otherDestination  The destination to fall back to.
     */
    historyGoBack(otherDestination: string) {
      if (history.length === 0) {
        history.push(otherDestination);
      } else {
        history.goBack();
      }
    },
  };
};

/**
 * A React hook to make it convenient to get properties of the store state. For
 * example: `const { tenant_id } = useStoreState();`
 */
export const useStoreState = () => {
  const { storeState, storeDispatch } = useContext(Store);
  const { user } = useAuthContext();
  return {
    ...storeState,
    storeDispatch,
    tenant_id: user?.seller_id ?? storeState.tenant_id,
  };
};

/**
 * A wrapper around `useForm` (react-hook-form). It logs any validation errors
 * to the console when the form is submitted. The type signature of this
 * function is the same as the type signature of `useForm`.
 */
export function useFormWrapper<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(
  props?: UseFormOptions<TFieldValues, TContext>
): UseFormMethods<TFieldValues> {
  const methodsOfUseForm = useForm<TFieldValues, TContext>(props);
  const { errors, formState, getValues } = methodsOfUseForm;

  if (!isEmpty(errors) && formState.submitCount >= 1) {
    const values = getValues();
    console.error(`form validation error: `, { errors, values });
  }
  return methodsOfUseForm;
}

/**
 * A function to create a Date object from a UTC datetime string. The datetime
 * string is in (implied) UTC timezone, but we want to create a Date object in
 * true UTC that will
 * let us display that datetime in the local timezone of the user.
 *
 * The JS `new Date()` constructor interprets string arguments as being in
 * local time, but our string is in UTC. So a JS date created from that string
 * is inaccurate for our purposes. So we have to correct that by passing
 * the "parsed" values (for year, month, etc.) to Date.UTC() which interprets
 * them as UTC values. Then the result of the Date.UTC() call is used to create
 * an accurate JS Date object that we can then display formatted in the users
 * local timezone.
 *
 * Note that the values from the backend are "implied UTC" in that they don't
 * actually have a UTC offset of zero in the string.
 *
 * Due to what I'd consider a bug in the ecmascript spec passing in null to this
 * function will return 1970-01-01T00:00:00.000Z. If null is passed in it will
 * be a runtime error.
 *
 * Because this throws an Error it should be considered "library code" and
 * should not be called directly. This is only exported because it has test coverage.
 *
 * @param utcDateTimeString - example: "2019-07-07T03:59:15.997000"
 */
export function makeDateFromUtcString(utcDateTimeString: string): Date {
  if (utcDateTimeString === null) {
    ErrorHandler.report(new Error("attempted to create date from null"));
    throw Error("can't create date from null, would return unix epoch");
  }
  const parsedDate = new Date(utcDateTimeString);
  return parsedDate;
}

/**
 *
 * TODO: make this function take in a locale to support international date
 * formats
 * EST date formatter function
 * for example 2019-07-07T03:59:15.997000 will return 11:59pm EST, Jul 6/19
 *
 *
 * This function works for US timezones but may display a differently
 * formatted string internationally. For example in Ecuador the same date
 * will return "-05" when formatted with `tz.guess()` but when formatted
 * with `.format("America/New_York") will return "EDT" for the timezone string.
 *
 * See this github issue with commentary from a moment developer:
 * @Link https://github.com/moment/moment-timezone/issues/841
 */
export const formatDateTime = (utcDateString: string) => {
  try {
    const accurateDate = makeDateFromUtcString(utcDateString);
    return moment(accurateDate).format("h:mmaz, MMM D/YY");
  } catch (error) {
    return "Invalid Date";
  }
};

/**
 * TODO: make this function take in a locale.
 * returns string like "08/31/21"
 * @param utcDateString
 * @param ignore_date_transform is used when the date is already in the correct format
 */
export const formatDate = (
  utcDateString: string,
  ignore_date_transform = false
) => {
  try {
    const accurateDate = ignore_date_transform
      ? utcDateString
      : makeDateFromUtcString(utcDateString);
    return ignore_date_transform
      ? moment.utc(accurateDate).format("MM/DD/YY")
      : moment(accurateDate).format("MM/DD/YY");
  } catch (error) {
    return "Invalid Date";
  }
};

/**
 * TODO: make this function take in a locale.
 * returns string like "08/31/21"
 * @param utcDateString
 */
export const getDateTime = (
  utcDateString: string,
  ignore_date_transform = false
) => {
  try {
    const accurateDate = ignore_date_transform
      ? utcDateString
      : makeDateFromUtcString(utcDateString);
    return {
      date: ignore_date_transform
        ? moment.utc(accurateDate).format("MM/DD/YYYY")
        : moment(accurateDate).format("MM/DD/YY"),
      dateFullYear: ignore_date_transform
        ? moment.utc(accurateDate).format("YYYY-MM-DD")
        : moment(accurateDate).format("YYYY-MM-DD"),
      time: moment(accurateDate).format("hh:mm:ss A"),
    };
  } catch (error) {
    return {
      date: "Invalid Date",
      dateFullYear: "Invalid Date",
      time: "Invalid time",
    };
  }
};

/**
 * Takes a date string and returns a JS Date. Takes into account the presence
 * of timezone indication. If the string doesn't include a timezone, assume it
 * is "implied UTC" (which is what the BE uses).
 */
const dateFromString = (date: string): Date | string => {
  if (date[date.length - 1] === "Z") {
    return new Date(date);
  } else {
    try {
      return makeDateFromUtcString(date);
    } catch (error) {
      return "Invalid Date";
    }
  }
};

/**
 * Takes two dates and returns true if they are equal and false if not.
 * Helpful for comparing date strings from the backend formatted like this,
 * without a timezone indication:
 *   "2021-11-30T17:00:00"
 * and date strings from the DatePicker component formatted like this, with a
 * timezone indication:
 *   "2021-11-29T17:00:00.000Z"
 */
export const twoDatesAreEqual = (dateOne: string, dateTwo: string): boolean => {
  const d1 = dateFromString(dateOne);
  const d2 = dateFromString(dateTwo);
  return d1.valueOf() === d2.valueOf();
};

// https://stackoverflow.com/a/59906630/1638735
type ArrayLengthMutationKeys =
  | "splice"
  | "push"
  | "pop"
  | "shift"
  | "unshift"
  | number;

type ArrayItems<T extends Array<any>> = T extends Array<infer TItems>
  ? TItems
  : never;

export type FixedLengthArray<T extends any[]> = Pick<
  T,
  Exclude<keyof T, ArrayLengthMutationKeys>
> & { [Symbol.iterator]: () => IterableIterator<ArrayItems<T>> };

type ShiftByProps = WithChildren<{
  x?: number;
  y?: number;
}>;

/**
 * Shift an element using translate when we need to closely align something
 * with something else. Example use case: lining up text to an svg.
 *
 * This is not supposed to be widely used for layout.
 * @param x
 * @param y
 * @param children
 * @constructor
 */
export function ShiftBy({ x = 0, y = 0, children }: ShiftByProps) {
  return (
    <div
      style={{
        transform: `translate(${x}px, ${y}px)`,
      }}
    >
      {children}
    </div>
  );
}

function getHumanReadableRelativeTime(date: string | null): string {
  const momentDate = moment(date);
  // Protect against "Invalid Date"
  if (!momentDate.isValid()) return "";

  // ensure the date is displayed with today and yesterday
  return momentDate.calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    sameElse: () => `[${momentDate.fromNow()}]`,
  });
}

const CapitalizeFirstLetter = styled.div`
  & ::first-letter {
    text-transform: uppercase;
  }
`;

/**
 * Returns a string like "Today" or "Yesterday" for a given date. In the
 * case of an invalid date return an empty string.
 * @param date
 */
export const getRelativeTime = (date: string | null): JSX.Element => (
  <CapitalizeFirstLetter>
    {getHumanReadableRelativeTime(date)}
  </CapitalizeFirstLetter>
);

/**
 * Given an error from a request to upload a file, return error messages to
 * show in an error notification and/or in the file upload UI.
 */
export const getFileUploadErrorText = (error: AxiosError) => {
  let notificationErrorText = "Upload failed, something went wrong.";
  let shortErrorText = "Error!";

  if (error.response?.status === 400) {
    if (error.response.data.error?.indexOf("400") === 0) {
      notificationErrorText = "Upload failed, Document format not supported.";
      shortErrorText = "Not supported.";
    } else if (error.response.data.error?.indexOf("413") === 0) {
      notificationErrorText = "Upload failed, Document is too large.";
      shortErrorText = "Document too large";
    }
  }
  return { notificationErrorText, shortErrorText };
};

/**
 * @returns string "street address city, state"
 * @param addr
 */
export function formatAddressStreetCityState(addr: IAddress): string {
  if (!addr) return "";
  return `${addr.address1} ${addr.city}, ${addr.state}`;
}

/**
 * @returns string "city, state"
 */

export function formatAddressCityState(address: IAddress): string {
  const StateNameOrCode =
    address.country === "JP" && address.subdivision_name
      ? address.subdivision_name
      : address.state;
  return `${address.city || "--"}, ${StateNameOrCode || "--"}`;
}

export function formatOrderItemLastPurchasePrice(
  item: IOrderItem,
  currencySymbol: string,
  t: (s: string) => string
): string {
  if (!item?.last_purchase) {
    return "--";
  }
  return `${formatLastPurchasePrice({
    numberOfUnits: item.number_of_units,
    pricePerUnit: item?.price_per_unit ?? "",
    currencySymbol: currencySymbol,
    sku: item.last_purchase.sku,
    t,
  })}`;
}

export function formatLastPurchasePrice({
  numberOfUnits,
  pricePerUnit,
  currencySymbol,
  sku,
  t,
}: {
  numberOfUnits: string | number;
  pricePerUnit: string | number;
  currencySymbol: string;
  sku?: ProductSKU;
  t: (s: string) => string;
}): string {
  const pTypeName = sku?.packaging_type?.name || "--";
  const pVolume = sku?.package_volume || "--";
  const pUnitName = sku?.packaging_unit?.name || "--";

  return (
    `${pTypeName} (${pVolume} ${pUnitName}) x ` +
    `${numberOfUnits} ${t(
      "units"
    )} @ ${pricePerUnit} ${currencySymbol}/${pUnitName}`
  );
}

/**
 * Convert an array of chips to array of string IDs. Filter out any with
 * missing IDs to avoid `undefined` IDs.
 */
export function convertChipsToStringIds(chips: ChipType[]): string[] {
  return chips
    .map((chip) => chip.id)
    .filter((id) => id)
    .map((id) => String(id));
}

export const convertProductSKUToOption = (
  sku: ProductSKU
): OptionType<ProductSKU> => {
  const FALLBACK_FOR_TESTING = "BUG WITH SKU MIGRATION";
  return {
    label: `${sku?.packaging_type?.name ?? FALLBACK_FOR_TESTING} (${
      sku?.package_volume ?? FALLBACK_FOR_TESTING
    } ${sku?.packaging_unit?.name ?? FALLBACK_FOR_TESTING})`,
    value: sku,
  };
};

/**
 * returns a "full" address like so: 1005 Coleman Drive Suit 307 Newark, DE US 55847
 * This function is smart enough to handle japanese addresses.
 *
 * @param addr
 */
export function formatFullAddress(addr: IAddress): string {
  const stateOrSubdivision =
    addr.country === "JP" ? addr.subdivision_name : addr.state;
  if (addr.address2) {
    return `${addr.address1} ${addr.address2} ${addr.city}, ${stateOrSubdivision} ${addr.country} ${addr.postal_code}`;
  } else {
    return `${addr.address1} ${
      addr.city ? `${addr.city},` : ""
    } ${stateOrSubdivision} ${addr.country} ${addr.postal_code}`;
  }
}

/**
 * Convert an address to an option for use in a menu input.
 * @see formatFullAdress is used internally.
 */
export const addressToOption = (address: IAddress): OptionType<string> => {
  return { label: formatFullAddress(address), value: address.id };
};

/**
 * Create a URL string from a base URL and a record of params.
 *
 * If the value of a param is `null` or `undefined` then that param is omitted.
 *
 * If the value of a param is an array of strings it becomes multiple params,
 * for example:
 *   { status: ["old", "new", "borrowed", "blue"] }
 * becomes:
 *   `?status=old&status=new&status=borrowed&status=blue`
 */
export const makeUrlWithParams = (
  baseUrl: string,
  params: Record<string, string | number | undefined | null | string[]>
): string => {
  const stringifiedParams = Object.entries(params).reduce(
    (result: [string, string][], [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => result.push([key, val]));
        return result;
      }
      // Allow value to be the number 0 or an empty string ("").
      if (value !== null && value !== undefined) {
        const stringValue = typeof value === "number" ? String(value) : value;
        result.push([key, stringValue]);
      }
      return result;
    },
    []
  );
  const urlSearchParams = new URLSearchParams(stringifiedParams);

  return baseUrl + "?" + urlSearchParams;
};

// TODO: eventually we may need a better solution
export const TEMPORARY_HIGH_LIMIT = 100;

/**
 * Returns the endpoint for getting "all" delivery terms for a given storefront.
 * Where "all" is currently capped at 100.
 */
export const makeDeliveryTermsGetEndpoint = (storefront_id: string) => {
  return makeUrlWithParams(
    endpoints.v1_storefronts_id_deliveryTerms(storefront_id),
    {
      limit: TEMPORARY_HIGH_LIMIT,
    }
  );
};

/**
 * Returns the endpoint for getting "all" payment terms for a given storefront.
 * Where "all" is currently capped at 100.
 */
export const makePaymentTermsGetEndpoint = (storefront_id: string) => {
  return makeUrlWithParams(
    endpoints.v1_storefronts_id_paymentTerms(storefront_id),
    {
      limit: TEMPORARY_HIGH_LIMIT,
    }
  );
};

/**
 * Returns the endpoint for getting "all" payment modes for a given storefront.
 * Where "all" is currently capped at 100.
 */
export const makePaymentModesGetEndpoint = (storefront_id: string) => {
  return makeUrlWithParams(
    endpoints.v1_storefronts_id_paymentModes(storefront_id),
    {
      limit: TEMPORARY_HIGH_LIMIT,
    }
  );
};

/**
 * Converts a payment term into an option to be used in a select menu.
 */
export function convertPaymentTermToOption(
  term: IPaymentTerm
): OptionType<string> {
  return term
    ? {
        value: term.id,
        label: term.name,
      }
    : { label: "", value: "" };
}

/**
 * Converts a payment mode into an option to be used in a select menu.
 */
export function convertPaymentModeToOption(
  term: IPaymentMode
): OptionType<string> {
  return term
    ? {
        value: term.id,
        label: term.name,
      }
    : { label: "", value: "" };
}

/**
 * Converts a delivery term into an option to be used in a select menu.
 */
export function convertDeliveryTermToOption(
  term: IDeliveryTerm
): OptionType<string> {
  return term
    ? {
        value: term.id,
        label: term.description
          ? `${term.name} - ${term.description}`
          : term.name,
      }
    : { label: "", value: "" };
}

export function convertApplicationToOption(
  application: ProductApplication
): OptionType<string> {
  return {
    value: application.id,
    label: application.name,
  };
}

/**
 * Places the default term at the start of the options list.
 * @param defaultTerm
 * @param options
 */
export function markDefaultTerm(
  options: Array<OptionType<string>>,
  defaultTerm?: OptionType<string>
): Array<OptionType<string>> {
  if (defaultTerm) {
    const withoutDefault = [...options].filter(
      (option) => option.value !== defaultTerm.value
    );
    return [defaultTerm, ...withoutDefault];
  }
  return options;
}

/**
 * Converts a user into an option to be used in a select menu.
 */
export function convertUserToOption(
  user: User | LeadAssignee
): OptionType<string> {
  return {
    value: user.id,
    label: `${user.firstname} ${user.lastname} - ${user.email_address}`,
  };
}

/**
 * Create a select box option where the value is the given users tenant_id.
 *
 * Q: why is this not combined with  @see `convertUserToOption` and made to return an
 * object that has both IDs?
 *
 * A: because react-select uses browser native `input type="select"` under the
 * hood and it would stringify the object. Yes you could JSON.parse but that
 * introduces its own problems.
 * @param user
 */
export function convertUserToOptionTenantID(user: User): OptionType<UUID> {
  return {
    value: user.tenant_id,
    label: `${user.firstname} ${user.lastname} - ${user.email_address}`,
  };
}

export function convertUserToOptionUserID(user: User): OptionType<UUID> {
  return {
    value: user.id,
    label: user.tenant_id,
  };
}

export function convertCustomerToOption(customer: Tenant): OptionType<string> {
  return {
    value: customer.id,
    label: customer.name,
  };
}

export function convertProductToOption(product: Product): OptionType<UUID> {
  return {
    value: product.id,
    label: product.name,
  };
}

export function makeCurrencyLabel(currency: Currency): string {
  return `${currency.name} (${currency.code}) - ${currency.symbol}`;
}

export function convertCurrencyToOption(
  currency: Currency
): OptionType<CurrencyCode> {
  return {
    value: currency.code,
    label: makeCurrencyLabel(currency),
  };
}

/**
 * A React hook that provides a currency object for a given currency code.
 */
export function useCurrency(currencyCode: CurrencyCode) {
  const { api_metadata } = useStoreState();

  const supportedCurrencies = api_metadata?.supported_currencies ?? [];

  const currency = supportedCurrencies.find(
    (currency) => currency.code === currencyCode
  );

  return currency;
}

/**
 * A React hook that provides the currency of the currently logged in user.
 */
export function useUserCurrency() {
  const { user } = useContext(Auth);
  const code = user?.settings?.preferred_currency || "USD";
  const currency = useCurrency(code);
  return currency;
}

// This is used in the rare case where we are trying to determine the currency
// symbol to show in the UI and cannot do so for some reason.
export const MISSING_CURRENCY_SYMBOL = "?";

/**
 * A React hook that provides the currency symbol for a given currency code.
 * Returns MISSING_CURRENCY_SYMBOL if no symbol can be found for the code.
 */
export function useCurrencySymbol(currencyCode: CurrencyCode): string {
  const { api_metadata } = useStoreState();

  const supportedCurrencies = api_metadata?.supported_currencies ?? [];

  const currency = supportedCurrencies.find(
    (currency) => currency.code === currencyCode
  );

  const symbol = currency?.symbol || MISSING_CURRENCY_SYMBOL;

  return symbol;
}

/**
 * Return a single metadata object that is either english (the default) or the
 * locale specific metadata if the storefront supports its.
 * @param storefront_metadata
 */
export function createStorefrontMetadataLocalization({
  storefront_metadata,
  cookie,
}: {
  storefront_metadata: StorefrontMetaData;
  cookie: string | undefined;
}): SingleStorefrontLocalization {
  const lang = getBrowserLanguage();

  if (
    storefront_metadata.metadata_localization &&
    cookie &&
    (storefront_metadata.metadata_localization[cookie] !== undefined ||
      cookie === "en")
  ) {
    // set language based on user selection.
    if (cookie === "en") {
      return {
        // return english, which is stored on the top level.
        // this can likely be removed in the future.
        browser_title: storefront_metadata.browser_title,
        header: storefront_metadata.header,
        placeholder: storefront_metadata.placeholder,
        is_active: true,
        sub_header: storefront_metadata.sub_header ?? "",
      };
    }
    const preferred = storefront_metadata.metadata_localization[cookie];
    return {
      browser_title: preferred.browser_title,
      header: preferred.header,
      placeholder: preferred.placeholder,
      is_active: preferred.is_active,
      sub_header: preferred.sub_header,
    };
  } else if (
    storefront_metadata.metadata_localization &&
    storefront_metadata.metadata_localization[lang] !== undefined
  ) {
    // set language based on browser, user has not changed their language in
    // this case.
    const local = storefront_metadata.metadata_localization[lang];
    return {
      browser_title: local.browser_title,
      header: local.header,
      placeholder: local.placeholder,
      is_active: local.is_active,
      sub_header: local.sub_header,
    };
  } else {
    return {
      // return english.
      browser_title: storefront_metadata.browser_title,
      header: storefront_metadata.header,
      placeholder: storefront_metadata.placeholder,
      is_active: true,
      sub_header: storefront_metadata.sub_header ?? "",
    };
  }
}

function isPromiseRejectedResult<T>(
  result: PromiseSettledResult<T>
): result is PromiseRejectedResult {
  return result.status === "rejected";
}

/**
 * Utility function that wraps Promise.allSettled and adds error logging and
 * throwing.
 *
 * If some of the promises are rejected, log the errors and throw the error
 * of the first one (like `Promise.all` does).
 */
export async function promiseAllSettledLogAndThrow<T>(promises: Promise<T>[]) {
  const results = await Promise.allSettled(promises);
  const rejected = results.filter(isPromiseRejectedResult);
  if (rejected.length > 0) {
    console.error("Some promises rejected", results);
    throw rejected[0].reason;
  }
  return results;
}

export const useHasMultipleLanguages = () => {
  const { storefront_metadata } = useStoreState();
  return (
    storefront_metadata.supported_languages_localized &&
    Object.keys(storefront_metadata.supported_languages_localized).length > 1
  );
};

/**
 * A React hook that provides the supported languages for a storefront.
 * Returns a single Object with `supportedLanguages` as an Option type Array,
 * `getLanguageLabel` and `getLanguageOption` .
 */
export function useSupportedLanguages() {
  const {
    storefront_metadata: {
      supported_languages_localized: supportedLanguagesRaw,
    },
  } = useStoreState();

  const supportedLanguageOptions: OptionType<SupportedLanguage>[] = Object.keys(
    supportedLanguagesRaw
  ).map((key) => ({
    value: key,
    label: `${supportedLanguagesRaw[key]} (${key.toUpperCase()})`,
  }));

  const getLanguageLabel = (languageCode: SupportedLanguage) => {
    return (
      supportedLanguageOptions.find((lang) => lang.value === languageCode)
        ?.label || "--"
    );
  };

  const getLanguageOption = (languageCode: SupportedLanguage) => {
    return supportedLanguageOptions.find((lang) => lang.value === languageCode);
  };

  const supported_languages = useMemo(
    () =>
      Object.entries(supportedLanguagesRaw).reduce(
        (acc, [langCode, langName]) => {
          acc[langCode] = `${langName} (${langCode.toUpperCase()})`;
          return acc;
        },
        {} as Record<string, string>
      ),
    [supportedLanguagesRaw]
  );

  return {
    supportedLanguageOptions,
    getLanguageLabel,
    getLanguageOption,
    supported_languages,
  };
}

export const replaceNewlineWithBr = (str: string) => {
  return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
};

export const replaceBrWithNewline = (str: string) => {
  return str.replace(/<br>/g, "\n");
};

export function isNumbersArray(arr: unknown[]): arr is number[] {
  return arr.every((price) => typeof price === "number" && !isNaN(price));
}

/**
 * Fixes the id on a product application. See the comments on the types.
 * @see ProductApplicationBrokenId
 */
export const fixProductApplication = (
  application: ProductApplicationBrokenId_DO_NOT_USE
): ProductApplication => {
  return {
    id: application.filter_id,
    name: application.name,
    image_url: application.image_url,
  };
};

/**
 * reusable hook for payment methods. This data is largely static so don't
 * revalidate by default.
 * @param storefront_id swr cache key
 * @param config swr config object
 */
export const usePaymentMethods = (
  storefront_id: string,
  config?: ConfigInterface<PaymentModePaginatedOutput>
) => {
  return useSWR<PaymentModePaginatedOutput>(
    makePaymentModesGetEndpoint(storefront_id),
    {
      ...config,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
};

export const useInAppNotifications = (storefront_id: string, user: User) => {
  const { data: notifications, mutate: mutateNotifications } =
    useSWR<InAppNotificationsResponse>(
      user ? endpoints.v1_storefronts_id_Notifications(storefront_id) : null,
      {
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
        revalidateOnMount: true,
      }
    );

  const ordersNotifications = notifications?.orders;
  const quotesNotifications = notifications?.quotes;
  const samplesNotifications = notifications?.samples;
  const leadsNotifications = notifications?.leads;

  const isUnread = (
    id: UUID,
    type:
      | "order"
      | "quote"
      | "sample"
      | "lead_contact"
      | "lead_register"
      | "lead_sample"
      | "lead_quote"
  ): boolean => {
    switch (type) {
      case "order":
        return ordersNotifications?.ids
          ? ordersNotifications?.ids.includes(id)
          : false;
      case "quote":
        return quotesNotifications?.ids
          ? quotesNotifications?.ids.includes(id)
          : false;
      case "sample":
        return samplesNotifications?.ids
          ? samplesNotifications?.ids.includes(id)
          : false;
      case "lead_contact":
        return leadsNotifications?.contact_us.ids
          ? leadsNotifications?.contact_us.ids.includes(id)
          : false;
      case "lead_register":
        return leadsNotifications?.registrations.ids
          ? leadsNotifications?.registrations.ids.includes(id)
          : false;
      case "lead_sample":
        return leadsNotifications?.sample_requests.ids
          ? leadsNotifications?.sample_requests.ids.includes(id)
          : false;
      case "lead_quote":
        return leadsNotifications?.quote_requests.ids
          ? leadsNotifications?.quote_requests.ids.includes(id)
          : false;
      default:
        return false;
    }
  };

  return {
    notifications,
    ordersNotifications,
    quotesNotifications,
    samplesNotifications,
    leadsNotifications,
    isUnread,
    mutateNotifications,
  };
};

export const createDownloadLink = ({
  name,
  type,
  data,
}: DownloadLinkOptions): HTMLAnchorElement => {
  // https://github.com/eligrey/FileSaver.js/blob/b5e61ec88969461ce0504658af07c2b56650ee8c/src/FileSaver.js#L29
  const blob = new Blob([String.fromCharCode(0xfeff), data], {
    type,
  });
  const link = document.createElement("a");
  link.download = name;
  link.href = URL.createObjectURL(blob);
  link.rel = "noopener";
  return link;
};

/**
 * Translations for custom labels used in the search & filter settings tab
 * @returns
 */
export const defaultCustomLabelTranslation = (
  filterType: string
): {
  [lang in SupportedLanguage]?: string;
} => {
  switch (filterType) {
    case "application":
      return {
        ja: "アプリケーション",
        zh: "应用",
      };
    case "function":
      return {
        ja: "機能",
        zh: "功能",
      };
    case "industry":
      return {
        ja: "産業",
        zh: "行业",
      };
    case "market_segment":
      return {
        ja: "市場区分",
        zh: "市场部门",
      };
    case "produced_by":
      return {
        ja: "プロデューサー",
        zh: "生产者",
      };
    case "proposition":
      return {
        ja: "バリュープロポジション",
        zh: "有价值的建议",
      };
    case "group1":
      return {
        ja: "製品グループ1",
        zh: "产品组 1",
      };
    case "group2":
      return {
        ja: "製品グループ2",
        zh: "产品组 2",
      };
    case "group3":
      return {
        ja: "製品グループ3",
        zh: "产品组 3",
      };
    default:
      return {
        ja: "",
        zh: "",
      };
  }
};

export const getFilterItemName = (name: string) => {
  switch (name) {
    case "application":
      return "Applications";
    case "function":
      return "Functions";
    case "group1":
      return "Product Group 1";
    case "group2":
      return "Product Group 2";
    case "group3":
      return "Product Group 3";
    case "industry":
      return "Industries";
    case "market_segment":
      return "Market Segments";
    case "produced_by":
      return "Produced By";
    case "proposition":
      return "Value Propositions";
    case "product_line":
      return "Product Line";
    case "status":
      return "Status";
    case "template":
      return "Template";
    default:
      return name;
  }
};

export const getCustomLabel = ({
  filter_type,
  tag_classification_configs,
  preferred_language,
}: {
  filter_type: string;
  tag_classification_configs: TagClassificationConfig[];
  preferred_language: SupportedLanguage;
}) => {
  const tag_classification_config = tag_classification_configs.find(
    (customLabel) => customLabel.filter_type === filter_type
  );
  if (tag_classification_config) {
    const filter_labels_obj: { [key: string]: FilterLabel } = {};
    tag_classification_config.filter_labels.forEach((filter_label) => {
      filter_labels_obj[filter_label.language] = filter_label;
    });
    const custom_label = filter_labels_obj[preferred_language]?.label;
    return custom_label ? custom_label : filter_labels_obj["en"].label;
  }
  return getFilterItemName(filter_type);
};

/**
 * Returns a string that will be displayed by a tooltip if data is a string
 * and there's a cell overflow
 * @param data: can be a string, object, bool or undefined.
 * @returns a string to be displayed by tooltip
 */
export const rowHover = (data: any | null | undefined) => {
  if (data && data.value && typeof data.value === "string") {
    return data.isTextOverflow ? data.value : "";
  }
  return "";
};

/**
 * Determines if the passed element overflows its bounds horizontally or vertically
 * https://stackoverflow.com/a/143889/7787330
 * @param el: HTMLElement
 * @returns {widthOverflow: boolean; heightOverflow: boolean}
 */
export const checkTextOverflow = (el: HTMLElement) => {
  const curOverflow = el.style.overflow;
  const curDisplay = el.style.display;
  // Temporarily modify the "overflow" style to detect this
  // if necessary.
  if (!curOverflow || curOverflow === "visible") {
    el.style.overflow = "hidden";
  }
  if (curDisplay === "inline") {
    el.style.display = "block";
  }

  const textOverflow = {
    widthOverflow:
      el.clientWidth > 10 ? el.clientWidth < el.scrollWidth : false,
    heightOverflow: el.clientHeight ? el.clientHeight < el.scrollHeight : false,
  };
  // resets the overflow to the initial value.
  el.style.overflow = curOverflow;
  el.style.display = curDisplay;

  return textOverflow;
};

export const isValidPhoneNumber = ({
  phone_number,
  country_code,
}: {
  phone_number: string;
  country_code: OptionType<string>;
}) => {
  const parser = new PhoneNumberUtil();
  if (phone_number && country_code) {
    let parsedPhoneNumber = null;
    try {
      parsedPhoneNumber = parser.parse(
        phone_number,
        countryCodeMap.get(country_code.value)
      );
    } catch (error) {
      return false;
    }
    return parser.isValidNumber(parsedPhoneNumber);
  }
  return false;
};

export const convertStringArrayToObj = (arr: string[]) => {
  return [...arr].reduce(
    (acc, curVal) => ({ ...acc, [curVal]: curVal }),
    {}
  ) as {
    [prop: string]: string;
  };
};
export const getDefaultFilterLabel = (
  filterType: string,
  supportedLanguages: OptionType<SupportedLanguage>[]
) => {
  const activeLanguages = supportedLanguages.map(({ value }) => value);
  const englishLabel = {
    language: "en",
    label: getFilterItemName(filterType),
  };
  return [
    englishLabel,
    ...activeLanguages.reduce<FilterLabel[]>((acc, language) => {
      if (language !== "en") {
        acc.push({
          language,
          label: defaultCustomLabelTranslation(filterType)?.[language] ?? "",
        });
      }
      return acc;
    }, []),
  ] as FilterLabel[];
};

export const updateCustomLabels = (
  labels: TagClassificationConfig[] | undefined,
  supportedLanguages: OptionType<SupportedLanguage>[]
) => {
  if (labels) {
    labels.forEach((customLabel, index) => {
      if (!customLabel.filter_labels.length) {
        labels?.splice(index, 1, {
          ...customLabel,
          filter_labels: getDefaultFilterLabel(
            customLabel.filter_type,
            supportedLanguages
          ),
        });
      }
    });
  }
};

/**
 * This is exists to avoid an error on the UI like
 * `payment_term must be a `object` type, but the final value was: `null`. If "null" is intended as an...`
 * avoid repition in the schemas.
 */
export const selectBoxDefaultValue = { value: "", label: "" };

export const convertSampleLabelToSampleStatus = (
  label: SampleStatusLabel
): SampleRequestStatus => {
  return match<SampleStatusLabel, SampleRequestStatus>(label)
    .with("Accepted", () => "accepted")
    .with("Completed", () => "completed")
    .with("In Progress", () => "in_progress")
    .with("Completed", () => "completed")
    .with("New", () => "new")
    .with("Shipped", () => "shipped")
    .with("Cancelled", () => "cancelled")
    .with("Rejected", () => "rejected")
    .with("Requested", () => "requested")
    .with("No Request", () => "no_request")
    .with("Pending Activation", () => "pending")
    .exhaustive();
};

export const convertSampleStatusToSampleLabel = (
  status: SampleRequestStatus
): SampleStatusLabel => {
  return match<SampleRequestStatus, SampleStatusLabel>(status)
    .with("accepted", () => "Accepted")
    .with("completed", () => "Completed")
    .with("in_progress", () => "In Progress")
    .with("shipped", () => "Shipped")
    .with("new", () => "New")
    .with("requested", () => "Requested")
    .with("rejected", () => "Rejected")
    .with("no_request", () => "No Request")
    .with("cancelled", () => "Cancelled")
    .with("pending", () => "Pending Activation")
    .with("pending_activation", () => "Pending Activation")
    .exhaustive();
};

export const convertSampleStatusToSampleStatusAction = (
  status: SampleRequestStatus
): SampleStatusAction => {
  switch (status) {
    case "accepted":
      return "accept";
    case "cancelled":
      return "cancel";
    case "in_progress":
      return "progress";
    case "completed":
      return "complete";
    case "shipped":
      return "ship_sample";
    default:
      return "accept";
  }
};

export const convertOrderLabelToOrderStatus = (
  label: OrderStatusLabel
): OrderStatus => {
  return match<OrderStatusLabel, OrderStatus>(label)
    .with("Accepted", () => "accepted")
    .with("Completed", () => "completed")
    .with("In Progress", () => "in_progress")
    .with("Invoiced/Payment Received", () => "invoiced")
    .with("Completed", () => "completed")
    .with("Placed", () => "new")
    .with("Closed", () => "closed")
    .with("Declined", () => "declined")
    .with("Payment Received", () => "payment_received")
    .with("Shipped", () => "shipped")
    .with("Cancelled", () => "cancelled")
    .with("Back Ordered", () => "back_ordered")
    .with("Pending Activation", () => "pending_activation")
    .with("Pending Cancellation", () => "pending_cancellation")
    .exhaustive();
};

export const convertOrderStatusToOrderLabel = (
  status: OrderStatus
): OrderStatusLabel => {
  return match<OrderStatus, OrderStatusLabel>(status)
    .with("accepted", () => "Accepted")
    .with("completed", () => "Completed")
    .with("in_progress", () => "In Progress")
    .with("invoiced", () => "Invoiced/Payment Received")
    .with("completed", () => "Completed")
    .with("new", () => "Placed")
    .with("closed", () => "Closed")
    .with("declined", () => "Declined")
    .with("cancelled", () => "Cancelled")
    .with("payment_received", () => "Payment Received")
    .with("shipped", () => "Shipped")
    .with("back_ordered", () => "Back Ordered")
    .with("pending_activation", () => "Pending Activation")
    .with("pending", () => "Pending Activation")
    .with("pending_cancellation", () => "Pending Cancellation")
    .exhaustive();
};

export const convertOrderStatusToOrderStatusAction = (
  status: OrderStatus
): OrderStatusAction => {
  switch (status) {
    case "accepted":
      return "accept";
    case "cancelled":
      return "cancel";
    case "in_progress":
      return "progress";
    case "invoiced":
      return "invoice";
    case "declined":
      return "decline";
    case "completed":
      return "complete";
    case "shipped":
      return "ship_order";
    default:
      return "accept";
  }
};

export const usePolicyDocuments = () => {
  const {
    storefront_metadata: { policy_documents },
  } = useStoreState();

  const privacyPolicy = policy_documents.find(
    (doc) => doc.kind === "privacy_policy"
  );
  const termsOfSalePolicy = policy_documents.find(
    (doc) => doc.kind === "terms_of_sale"
  );

  return { privacyPolicy, termsOfSalePolicy };
};

export const AddressFormSchema = (t: TFunction) => ({
  company_name: yup.string().required(strings(t).thisIsARequiredField),
  country: yup
    .object()
    .shape({ label: yup.string().required(), value: yup.string().required() })
    .required(strings(t).thisIsARequiredField),
  county: yup.string().nullable().notRequired(),
  crm_id: yup.string().nullable().notRequired(),
  address1: yup.string().required(strings(t).thisIsARequiredField),
  address2: yup.string().nullable().notRequired(),
  state: yup
    .object()
    .nullable()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required(strings(t).thisIsARequiredField),
  city: yup.string().required(strings(t).thisIsARequiredField),
  postal_code: yup.string().required(strings(t).thisIsARequiredField),
});

export type POCManualFormSchemaType = {
  contact_first_name: string;
  contact_last_name: string;
  email_address: string;
  phone_number: string;
  country_code: { label: string; value: string };
};

export type POCFormSchemaType = {
  point_of_contact_id: { label: string; value: string };
};

export const POCManualFormSchema = (t: TFunction, formValues: any) => ({
  contact_first_name: yup.string().required(strings(t).thisIsARequiredField),
  contact_last_name: yup.string().required(strings(t).thisIsARequiredField),
  email_address: yup.string().email().required(strings(t).thisIsARequiredField),
  phone_number: yup
    .string()
    .phone(
      countryCodeMap.get(formValues.country_code?.value),
      false,
      strings(t).phoneNumberMustBeValid
    )
    .required(strings(t).thisIsARequiredField),
  country_code: yup
    .object()
    .shape({
      label: yup.string().required(strings(t).thisIsARequiredField),
      value: yup.string().required(strings(t).thisIsARequiredField),
    })
    .required(strings(t).thisIsARequiredField),
});

export const POCFormSchema = (t: TFunction) => ({
  point_of_contact_id: yup
    .object()
    .shape({
      label: yup.string().required(strings(t).thisIsARequiredField),
      value: yup.string().required(strings(t).thisIsARequiredField),
    })
    .nullable()
    .required(strings(t).thisIsARequiredField),
});

export const getAddressUserOption = (address: IAddress) => {
  if (address.point_of_contact_id) {
    const userOption = {
      value: address.point_of_contact_id,
      label: `${address.contact_name} - ${address.email_address}`,
    };
    return userOption;
  }
  return undefined;
};

export const toTitleCase = (val: string) =>
  val
    .trim()
    .split(" ")
    .map((item) => `${item.charAt(0).toUpperCase()}${item.slice(1)}`)
    .join(" ");

export const removeUnderscore = (val: string) => val.replace("_", " ");

export const defaultHandleSort = <TableData extends object>(
  rules: SortingRule<object>[],
  sortingRules: { sortBy?: string | undefined; orderBy: "asc" | "desc" },
  setSortingRules: React.Dispatch<
    React.SetStateAction<{
      sortBy?: string | undefined;
      orderBy: "asc" | "desc";
    }>
  >,
  setTableData: React.Dispatch<React.SetStateAction<TableData[]>>
) => {
  if (rules.length > 0) {
    const { id, desc } = rules[0];

    // desc is a boolean that is true is descending, false if not.
    const order = desc ? "desc" : "asc";
    if (id !== sortingRules.sortBy || order !== sortingRules.orderBy) {
      setSortingRules({ sortBy: id, orderBy: order });
      setTableData([]);
    }
  }
};

/**
 * This fetches routes that are restricted in a given edition
 * @param edition Storefront edition
 * @returns
 */
export const getRestrictedRoutes = (edition: StorefrontEdition) => {
  switch (edition) {
    case "business":
      return ["prospects"];
    case "enterprise":
      return [];
    case "starter":
      return ["orders", "samples", "quotes", "prospects"];
    case "pim":
      return [
        "orders",
        "samples",
        "quotes",
        "leads",
        "prospects",
        "my-products",
      ];
    default:
      return [];
  }
};

/**
 * used to enforce switch blocks being exhaustive.
 * @param x
 */
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export interface IIsOutOfView {
  isOutTop: boolean;
  isOutLeft: boolean;
  isOutRight: boolean;
  isOutBottom: boolean;
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export function isOutOfViewport(
  elem: React.MutableRefObject<HTMLDivElement | HTMLButtonElement | null>
) {
  // Get element's bounding
  const bounding = elem?.current?.getBoundingClientRect();
  if (bounding) {
    const out: IIsOutOfView = {
      // Check if it's out of the viewport on each side
      isOutTop: bounding?.top < 0,
      isOutLeft: bounding?.left < 0,
      isOutBottom:
        bounding?.bottom >
        (window.innerHeight || document.documentElement.clientHeight),
      isOutRight:
        bounding.right >
        (window.innerWidth || document.documentElement.clientWidth),
      top: bounding?.top,
      left: bounding?.left,
      right: document.documentElement.clientWidth - bounding?.right,
      bottom: document.documentElement.clientHeight - bounding?.bottom,
    };
    return out;
  }
  return undefined;
}

/**
 * converts string[] to ChipType[], handling null values.
 * @param values
 */
export function convertToChipArray(
  values: typeof ArrayParam | unknown
): ChipType[] | null {
  if (values === null) return null;
  if (values === undefined) return null;
  if (
    Array.isArray(values) &&
    !isEmpty(values) &&
    values.every((item: unknown) => typeof item === "string")
  ) {
    return values.map((value: string) => ({ name: value }));
  } else return null;
}

/**
 * Wrapper around placeholder
 */
export const TablePlaceholderWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 16px;
  background-color: ${({ theme }) => theme.primaryBG};
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
  border-top: 0;
`;

export const TablePlaceholder = ({ message }: { message?: string }) => {
  const { t } = useTranslation();
  return (
    <TablePlaceholderWrapper>
      {message ?? t("No items to show.")}
    </TablePlaceholderWrapper>
  );
};

/**
 * Updates a product status, depending on its current status as follows:
 * published -> staged; unpublished -> unpublished_staged.
 * It returns same product given as a param, if the product status is not required to change.
 * It's the first API call in a chain of API calls for product edit
 * eg: const updateProductStatus = useUpdateProductStatus({product});
 * try {
 *    updateProductStatus().then(({data: product_with_updated_status}) => updateProduct(product_with_updated_status))
 * } catch (error) {
 *    console.error(error);
 * }
 * @param {product: PIMProduct}
 * @returns Promise<AxiosResponse<PIMProduct>>
 * intentionally throws an error when request fails
 */
export const useUpdateProductStatus = ({
  product,
}: {
  product: PIMProduct | PIMProductBase;
}) => {
  const { storefront_id } = useStoreState();

  const updateProductStatus = async () =>
    product.status === "published" || product.status === "unpublished"
      ? await axios.post<
          { status: ProductStatusType },
          AxiosResponse<PIMProduct>
        >(
          endpoints.v2_storefronts_id_or_slug_products_id_or_slug_status(
            storefront_id,
            product.product_number ?? product.id
          ),
          {
            status:
              product.status === "published" ? "staged" : "unpublished_staged",
          }
        )
      : Promise.resolve({ data: product } as AxiosResponse<PIMProduct>);

  return updateProductStatus;
};

type GetAttributeWithObjectTypeProps = {
  // The names of the default groups, these frequently exist but may not.
  group_name: "identifiers" | "general" | "classifications" | "tags" | "SEO";
  object_type: AttributeObjectType;
  product: PIMProduct;
};

/**
 * Get system default attribute you can expect to likely exist for most
 * products. Still must handle the null return case for weird products
 * @param param0
 * @returns
 */
export function getAttributeWithObjectType<
  T extends string | number | boolean | AttributeValue[] | null
>({
  group_name,
  object_type,
  product,
}: GetAttributeWithObjectTypeProps): T | null {
  const attribute = product.product_schema.groups
    .find((group) => group.name.toLowerCase() === group_name)
    ?.attributes?.find((attr) => attr.object_type === object_type);

  if (attribute && attribute.values) {
    const attributeValueWithMetaData = attribute.values[0];
    const value = attribute.values[0].value;
    if (!value) return null;
    else {
      return (
        match(attributeValueWithMetaData.data_type)
          .with("boolean", () => Boolean(value))
          .with("date", () => value)
          .with("numeric", () => Number(value))
          .with("text", () => value)
          // this is for `multi_select`, we actually want to return all values
          // in the array. This is not handled separately so that we can have
          // the `exhaustive` flag here.
          .with("enum", () => attribute.values)
          .with("document", () => value)
          .exhaustive() as T
      );
    }
  } else return null;
}

export const GetFiltersFromURL = () => {
  const { search } = useLocation();
  var queryStart = search.indexOf("?") + 1,
    queryEnd = search.indexOf("#") + 1 || search.length + 1,
    query = search.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = [],
    i,
    n,
    v,
    nv;

  if (query === search || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);
    if (n !== "q") {
      parms.push({ type: n, label: v });
    }
  }
  return parms;
};

export const isCustomerUserType = (user: User) =>
  user.user_type === "Buyer Admin" ||
  user.user_type === "Buyer Standard" ||
  user.user_type === "Distributor" ||
  user.user_type === "Distributor Admin";

export const PIM_PRODUCT_DETAILS_PORTFOLIO_TAB =
  "pim_product_details_portfolio_tab";

export const useFirstViewportEntery = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  observerOptions: { threshold: number }
) => {
  const [entered, setEntered] = useState(false);
  const observer = useRef(
    new IntersectionObserver(
      ([entery]) => setEntered(entery.isIntersecting),
      observerOptions
    )
  );

  useEffect(() => {
    const element = ref.current;
    const ob = observer.current;
    if (entered) {
      ob.disconnect();
      return;
    }
    if (element && !entered) ob.observe(element);
    return () => ob.disconnect();
  }, [entered, ref]);

  return entered;
};

export const RenderOnViewportEntry = ({
  children,
  threshold = 0.1,
  minHeight = "200px",
}: {
  children: ReactNode;
  threshold?: number;
  minHeight?: string;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entered = useFirstViewportEntery(ref, { threshold });
  return (
    <div ref={ref} style={{ minHeight: minHeight }}>
      {entered && (
        <Suspense fallback={<Loader isLoading={true} />}>{children}</Suspense>
      )}
    </div>
  );
};
