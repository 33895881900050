import styled, { useTheme } from "styled-components/macro";
import type {
  Assets,
  AttributeSchema,
  ImageAttributeValue,
} from "../../types/types.PIM";
import { useMemo, useState } from "react";
import { SlideOut } from "../SlideOut/SlideOut";
import { DocumentPreview } from "../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/DocumentPreview";
import { AddOrEditImageAttribute } from "../../pages/admin/SellerAdmin/PIM/SellerAdminPIMAttributes/ImageAttributes/AddOrEditImageAttribute";
import { InfoIcon, LockIcon, NonVisibleIcon } from "../Icons/Icons";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { EditButton } from "../Buttons/Buttons";

const ImageCaptionContainer = styled.div<{
  size: ImageAttributeValue["image_dimensions"];
  is_downloadable: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  height: ${({ is_downloadable }) => (is_downloadable ? "unset" : "350px")};
  width: ${({ size }) =>
    size === "small" ? "30%" : size === "medium" ? "50%" : "100%"};
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  p {
    margin: 0;
  }
  background-color: ${({ is_downloadable }) =>
    is_downloadable ? "unset" : "#eee"};
  justify-content: ${({ is_downloadable }) =>
    is_downloadable ? "unset" : "center"};
  cursor: ${({ is_downloadable }) =>
    is_downloadable ? "pointer" : "not-allowed"};
`;

export const ImageAttributePreview = ({
  asset,
  attribute,
  onCreateImageAttribute,
  image_attribute_value,
  is_portfolio_page = false,
  can_show_icons = false,
}: {
  asset: Assets;
  attribute: AttributeSchema;
  onCreateImageAttribute?: (
    image_arr: ImageAttributeValue | null,
    attribute: AttributeSchema
  ) => void;
  image_attribute_value?: ImageAttributeValue;
  is_portfolio_page?: boolean;
  can_show_icons?: boolean;
}) => {
  const [show_preview, set_show_preview] = useState(false);
  const [show_edit_slideout, set_show_edit_slideout] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const image_url = useMemo(() => {
    return attribute?.values?.[0]?.cdn_url ?? asset.signed_url;
  }, [asset.signed_url, attribute?.values]);

  const onEdit = () => {
    set_show_edit_slideout(true);
  };

  const onDownload = () => {
    if (image_url.includes("https")) {
      // create an anchor tag and click it to download the image
      const image_download_elem = document.createElement("a");
      image_download_elem.href = image_url;
      image_download_elem.download = asset.name;
      image_download_elem.rel = "noreferrer";
      image_download_elem.target = "_blank";
      image_download_elem.click();
    } else {
      window.open(image_url, "_blank");
    }
  };

  const onEditComplete = (image_attribute: ImageAttributeValue | null) => {
    set_show_edit_slideout(false);
    set_show_preview(false);
    onCreateImageAttribute?.(image_attribute, attribute);
  };

  const asset_to_preview = {
    ...asset,
    can_edit: !is_portfolio_page && !!onCreateImageAttribute,
    can_change_visibility: false,
    can_delete: false,
    can_external_download: is_portfolio_page ? asset.is_downloadable : true,
    can_view_details: !is_portfolio_page,
  };

  const caption =
    image_attribute_value?.caption ??
    (attribute?.values?.[0]?.value as ImageAttributeValue)?.caption ??
    "";
  const size =
    image_attribute_value?.image_dimensions ??
    (attribute?.values?.[0]?.value as ImageAttributeValue)?.image_dimensions ??
    "small";

  const is_downloadable = is_portfolio_page
    ? attribute.values[0].is_downloadable ?? true
    : true;

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <ImageCaptionContainer
        is_downloadable={is_downloadable}
        size={size}
        onClick={() => {
          set_show_preview(true);
        }}
        data-for="locked_asset"
        data-tip={
          is_downloadable
            ? ""
            : t("This image asset is not accessible outside login.")
        }
      >
        {is_downloadable ? (
          <>
            <img src={image_url} alt={caption} />
            {caption && <p>{caption}</p>}
          </>
        ) : (
          <div className="lock_icon">
            <LockIcon fill={theme.primaryIconColor} />
          </div>
        )}
        {!is_downloadable && <ReactTooltip effect="solid" id="locked_asset" />}
      </ImageCaptionContainer>
      {show_preview && (
        <DocumentPreview
          assets={[asset_to_preview]}
          selected_asset_idx={0}
          onCancelPreview={() => set_show_preview(false)}
          onEdit={asset_to_preview.can_edit ? onEdit : undefined}
          onDownload={
            asset_to_preview.can_external_download ? onDownload : undefined
          }
        />
      )}
      <SlideOut
        show={show_edit_slideout}
        closeFlyout={() => set_show_edit_slideout(false)}
      >
        <AddOrEditImageAttribute
          attribute={attribute}
          type="edit"
          onComplete={onEditComplete}
          onCancel={() => set_show_edit_slideout(false)}
          existing_asset={asset}
          image_attribute_value={image_attribute_value}
        />
      </SlideOut>
      {can_show_icons && (
        <>
          {attribute.description && (
            <>
              <div
                style={{ height: "fit-content" }}
                data-tip={attribute.description}
                data-for="info-tip"
              >
                <InfoIcon fill={theme.secondaryIconColor} />
              </div>
              <ReactTooltip
                delayHide={500}
                id="info-tip"
                clickable
                effect="solid"
              />
            </>
          )}
          {attribute.is_restricted && (
            <>
              <div
                style={{ height: "fit-content" }}
                data-for="restricted-tip"
                data-tip={t(
                  "This attribute is only visible for internal users"
                )}
              >
                <NonVisibleIcon
                  width={24}
                  height={24}
                  fill={theme.secondaryIconColor}
                />
              </div>
              <ReactTooltip
                delayHide={500}
                id="restricted-tip"
                effect="solid"
              />
            </>
          )}
          {asset_to_preview.can_edit && (
            <EditButton type="button" onClick={onEdit} />
          )}
        </>
      )}
    </div>
  );
};
